import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import initFirebaseAppComponent from "@wellingtonsteve/jscommon/initFirebaseAppComponent";
import LoggedInApp from "./components/money/LoggedInApp";

const FirebaseApp = initFirebaseAppComponent({
  apiKey: "AIzaSyC_tIGUQKKFPgl5odydXablsIXIye7vjAc",
  authDomain: "money-7b1af.firebaseapp.com",
  projectId: "money-7b1af",
  storageBucket: "money-7b1af.appspot.com",
  messagingSenderId: "846819902171",
  appId: "1:846819902171:web:210e7e2c2efcd104bd42fe",
});

ReactDOM.render(
  <React.StrictMode>
    <FirebaseApp>
      <LoggedInApp />
    </FirebaseApp>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
