export const parsePennies = amountAsString => {
  const isNegative = amountAsString.indexOf("-") !== -1;
  const absAmountAsString = amountAsString.replace(/[^0-9\\.]+/g, "");
  const parts = absAmountAsString.split(".");
  const pounds = parseInt(parts[0]);

  const penniesPart = parts[1] || "";
  const paddedPennies = (penniesPart + "00").substr(0, 2);
  const pennies = parseInt(paddedPennies);
  return (isNegative ? -1 : 1) * (pounds * 100 + pennies);
};
