import Papa from "papaparse";
import { StatementRow } from "../StatementRow";
import { parsePennies } from "./parsePennies";
import { parseDate_dd_mmm_yyyy, parseDate_ddmmyyyy } from "./dateParsing";

const formats = {
  NATWEST: {
    preprocessFunction: x => x,
    dateParseFunction: parseDate_ddmmyyyy,
    reverseRows: true,
    invertAmounts: false,
    amountKey: "Value",
    importedBalanceKey: "Balance",
    descriptionKey: "Description",
  },
  AMEX: {
    preprocessFunction: x => x,
    dateParseFunction: parseDate_ddmmyyyy,
    reverseRows: true,
    invertAmounts: true,
    amountKey: "Amount",
    descriptionKey: "Description",
  },
  STARLING: {
    preprocessFunction: x => x,
    dateParseFunction: parseDate_ddmmyyyy,
    reverseRows: false,
    invertAmounts: false,
    amountKey: "Amount (GBP)",
    importedBalanceKey: "Balance (GBP)",
    descriptionFunction: line => `${line.Reference.trim()} (${line["Type"]})`,
  },
  NATIONWIDE: {
    preprocessFunction: text => text.substring(text.indexOf("Date") - 1),
    dateParseFunction: parseDate_dd_mmm_yyyy,
    reverseRows: false,
    invertAmounts: false,
    amountFunction: line =>
      line["Paid out"].length > 0 ? `-${line["Paid out"]}` : line["Paid in"],
    importedBalanceKey: "Balance",
    descriptionKey: "Description",
  },
  NEWDAY: {
    preprocessFunction: x => x,
    dateParseFunction: parseDate_ddmmyyyy,
    reverseRows: false,
    invertAmounts: true,
    amountKey: "Amount(GBP)",
    descriptionKey: "Description",
  },
  NSANDI: {
    preprocessFunction: x => x,
    dateParseFunction: parseDate_ddmmyyyy,
    reverseRows: true,
    invertAmounts: false,
    amountKey: "Amount",
    descriptionKey: "Description",
    importedBalanceKey: "Balance",
  }
};

const parseCsvStatement = (csv, dbFinalBalance, formatKey) => {
  const format = formats[formatKey];
  const parseResult = Papa.parse(format.preprocessFunction(csv), {
    skipEmptyLines: "greedy",
    header: true,
  });
  const parsedRows = parseResult.data;
  if (format.reverseRows) {
    parsedRows.reverse();
  }
  let runningBalance = dbFinalBalance;
  return parsedRows.map((line, index) => {
    const amountStr = format.amountKey ? line[format.amountKey] : format.amountFunction(line);
    const amount = (format.invertAmounts ? -1 : 1) * parsePennies(amountStr);
    return new StatementRow(
      index,
      format.dateParseFunction(line.Date),
      format.descriptionKey ? line[format.descriptionKey] : format.descriptionFunction(line),
      [],
      amount,
      format.importedBalanceKey ? parsePennies(line[format.importedBalanceKey]) : null,
      (runningBalance += amount),
      null,
      line
    );
  });
};

parseCsvStatement.formatKeys = Object.keys(formats);

export default parseCsvStatement;
