import React, { useCallback, useEffect, useState } from "react";
import useCollectionSubscription from "@wellingtonsteve/jscommon/useCollectionSubscription";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { dateToTimestamp } from "@wellingtonsteve/jscommon/timestampUtils";
import { StatementTable } from "./StatementTable";
import { StatementRow } from "./StatementRow";
import parseCsvStatement from "./parsing/parseCsvStatement";
import parseNationwideMortgageStatement from "./parsing/nationwide-mortgage/parseNationwideMortgageStatement";

const Statements = ({ indexedParties, datasetDoc }) => {
  const [selectedAccount, setSelectedAccount] = useState(undefined);
  return (
    <div className="dashboard">
      <h1>Pick an account:</h1>
      <ul>
        {indexedParties.ids.map(partyId => (
          <Party
            key={partyId}
            partyDoc={indexedParties.docs[partyId]}
            datasetDoc={datasetDoc}
            setSelectedAccount={setSelectedAccount}
          />
        ))}
      </ul>
      {!!selectedAccount && (
        <Account
          datasetDoc={datasetDoc}
          indexedParties={indexedParties}
          selectedAccount={selectedAccount}
        />
      )}
    </div>
  );
};

const groupBy = (xs, fn) =>
  xs.reduce((rv, x) => {
    (rv[fn(x)] = rv[fn(x)] || []).push(x);
    return rv;
  }, {});

const Party = ({ partyDoc, datasetDoc, setSelectedAccount }) => {
  const accountsPath = "parties/" + partyDoc.id + "/accounts";
  const [accountDocs] = useCollectionSubscription(datasetDoc, accountsPath);

  const groupedAccountDocs = groupBy(accountDocs || [], accountDoc => accountDoc.data().bank);
  const banks = Object.keys(groupedAccountDocs);
  banks.sort();

  return (
    !!accountDocs &&
    accountDocs.length > 0 && (
      <li style={{ marginBottom: ".1rem" }}>
        <strong>{partyDoc.data().name}</strong>{" "}
        {banks.length > 0 && (
          <span>
            {banks.map(bank => (
              <span key={bank} style={{ marginRight: ".5rem" }}>
                {bank}:{" "}
                {groupedAccountDocs[bank].map(accountDoc => (
                  <Button
                    size="sm"
                    key={accountDoc.id}
                    onClick={() =>
                      setSelectedAccount({ party: partyDoc.id, account: accountDoc.id })
                    }
                    style={{ marginRight: ".1rem" }}
                  >
                    {accountDoc.data().name}
                  </Button>
                ))}
              </span>
            ))}
          </span>
        )}
      </li>
    )
  );
};

const useDocSubscription = (path, subPath, loggingName = subPath) => {
  const [doc, setDoc] = useState(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log("Subscribing to " + loggingName);
    setLoading(true);
    const docToSubscribe =
      subPath === undefined ? path : path.firestore.doc(path.path + "/" + subPath);
    const unsubscribe = docToSubscribe.onSnapshot(doc => {
      setDoc(doc);
      setLoading(false);
    });
    return () => {
      console.log("Unsubscribing from " + loggingName);
      unsubscribe();
    };
  }, [path, subPath, loggingName]);
  return [doc, loading];
};

const Account = ({ datasetDoc, indexedParties, selectedAccount }) => {
  const accountPath = "parties/" + selectedAccount.party + "/accounts/" + selectedAccount.account;
  const [accountDoc] = useDocSubscription(datasetDoc, accountPath);
  const statementEntriesPath = accountPath + "/statementEntries";
  const [statementRowDocs] = useCollectionSubscription(datasetDoc, statementEntriesPath);
  const partyDoc = indexedParties.docs[selectedAccount.party];
  return (
    !!accountDoc &&
    !!statementRowDocs && (
      <div>
        <AccountHeader partyDoc={partyDoc} accountDoc={accountDoc} />
        <Statement
          key={[selectedAccount.party, selectedAccount.account]}
          accountDoc={accountDoc}
          statementRowDocs={statementRowDocs}
          commitExtraRows={newRows => {
            let prev = accountDoc.data().statementHead;
            newRows.forEach(newRow => {
              const newDoc = accountDoc.ref.collection("statementEntries").doc();
              newDoc.set({
                date: dateToTimestamp(newRow.date),
                description: newRow.description,
                subDescriptions: newRow.subDescriptions,
                amount: newRow.amount,
                importedBalance: newRow.importedBalance,
                calculatedBalance: newRow.calculatedBalance,
                parsingMetadata: newRow.parsingMetadata,
                rawData: newRow.rawData,
                prev: !!prev ? prev : null,
              });
              prev = newDoc.id;
            });
            return accountDoc.ref.set({ statementHead: prev }, { merge: true });
          }}
          recordValidationText={(id, validationText) =>
            accountDoc.ref
              .collection("statementEntries")
              .doc(id)
              .set({ validationText: validationText }, { merge: true })
          }
        />
      </div>
    )
  );
};

const AccountHeader = ({ partyDoc, accountDoc }) => (
  <h1>
    {partyDoc.data().name} ▶ {accountDoc.data().bank} ▶ {accountDoc.data().name}
  </h1>
);

const followDocPointers = (docs, firstId) => {
  const indexedEntries = docs.reduce((docs, doc) => ({ ...docs, [doc.id]: doc }), {});
  let orderedEntries = [];
  let nextId = firstId;
  let seenValidationText = false;
  while (!!nextId && !!indexedEntries[nextId]) {
    const nextDoc = indexedEntries[nextId];
    const validationText = nextDoc.data().validationText;
    seenValidationText =
      seenValidationText || (validationText !== null && validationText !== undefined);
    orderedEntries = [
      StatementRow.fromDoc(nextDoc, seenValidationText, validationText),
      ...orderedEntries,
    ];
    nextId = nextDoc.data().prev;
  }
  return orderedEntries;
};

const Statement = ({ accountDoc, statementRowDocs, commitExtraRows, recordValidationText }) => {
  const [uploadedContent, setUploadedContent] = useState(undefined);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileRejections.forEach(rejection => {
      console.log(
        `Rejected ${rejection.file.name} because ${rejection.errors.map(error => error.message)}`
      );
    });
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => setUploadedContent({ name: file.name, content: reader.result });
      reader.readAsText(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    accept: "text/csv,application/vnd.ms-excel",
    multiple: 0,
  });

  const dbStatementRows = followDocPointers(statementRowDocs, accountDoc.data().statementHead);
  const dbInitialBalance = accountDoc.data().initialBalance;
  const dbFinalBalance =
    dbStatementRows.length > 0
      ? dbStatementRows[dbStatementRows.length - 1].calculatedBalance
      : dbInitialBalance;

  let parsedInput;
  if (!!uploadedContent) {
    const statementFormat = accountDoc.data().statementFormat;
    if (statementFormat === "NATIONWIDE_MORTGAGE") {
      const previousParsingMetadata =
        dbStatementRows.length > 0
          ? dbStatementRows[dbStatementRows.length - 1].parsingMetadata
          : undefined;
      parsedInput = parseNationwideMortgageStatement(
        uploadedContent.content,
        dbFinalBalance,
        previousParsingMetadata
      );
    } else {
      parsedInput = parseCsvStatement(uploadedContent.content, dbFinalBalance, statementFormat);
    }
  }

  return (
    <div>
      <StatementTable
        statementRows={dbStatementRows}
        extraRows={parsedInput}
        initialBalance={dbInitialBalance}
        recordValidationText={recordValidationText}
      >
        {!!parsedInput ? (
          <tr className="extraHeader" {...getRootProps()}>
            <td colSpan="4" style={{ border: isDragActive ? "5px solid blue" : "" }}>
              ▼ Imported rows ▼{" "}
              <Button
                onClick={() =>
                  commitExtraRows(parsedInput).then(() => setUploadedContent(undefined))
                }
              >
                Save
              </Button>{" "}
              <Button onClick={() => setUploadedContent(undefined)}>Cancel</Button>
            </td>
          </tr>
        ) : (
          <tr className="extraHeader" {...getRootProps()}>
            <td colSpan="4" style={{ border: isDragActive ? "5px solid blue" : "" }}>
              <input {...getInputProps()} />
              To import more, drag a statement CSV file here
            </td>
          </tr>
        )}
      </StatementTable>
    </div>
  );
};

export default Statements;
