import React, { useState } from "react";
import { Col, Form, OverlayTrigger, Popover, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Amount } from "../common/Amount";
import useCollectionSubscription from "@wellingtonsteve/jscommon/useCollectionSubscription";
import parseCsvStatement from "../statements/parsing/parseCsvStatement";

const applySuffix = (idPrefix, suffix) => (suffix > 1 ? idPrefix + "_" + suffix : idPrefix);
const generateId = (name, usedIds) => {
  const idPrefix = name
    .toLowerCase()
    .replace(/ +/g, "")
    .replace(/\W/g, "");
  let suffix = 1;
  while (usedIds.includes(applySuffix(idPrefix, suffix))) {
    suffix++;
  }
  return applySuffix(idPrefix, suffix);
};

const AddPartyForm = ({ newPartyAction, usedIds }) => {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const id = generateId(name, usedIds);

  const reset = () => {
    setValidated(false);
    setName("");
  };

  const handleSubmit = event => {
    setValidated(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      newPartyAction(id, name);
      reset();
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Row>
        <Col xs="auto">
          <Form.Label srOnly>Name</Form.Label>
          <Form.Control
            value={name}
            onChange={event => setName(event.target.value)}
            required
            type="text"
            placeholder="Party name"
          />
        </Col>
        <Col xs="auto">
          <Button variant="primary" type="submit" disabled={id.length === 0}>
            {id.length > 0 ? `Add party (${id})` : "Add party"}
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};

const AddAccountForm = ({ newAccountAction, usedIds }) => {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [bank, setBank] = useState("");
  const [initialBalance, setInitialBalance] = useState(0);
  const [statementFormat, setStatementFormat] = useState("");
  const id = generateId(bank + name, usedIds);

  const reset = () => {
    setValidated(false);
    setName("");
    setBank("");
    setInitialBalance(0);
    setStatementFormat("");
  };

  const handleSubmit = event => {
    setValidated(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      newAccountAction(id, name, bank, initialBalance, statementFormat);
      reset();
    }
  };

  const statementFormatPopover = (
    <Popover id="statementFormatPopover">
      <Popover.Content>
        {parseCsvStatement.formatKeys.map(key => (
          <div key={key}>
            <Button size="sm" onClick={() => setStatementFormat(key)}>
              {key}
            </Button>
          </div>
        ))}
      </Popover.Content>
    </Popover>
  );

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Row>
        <Col xs="auto">
          <Form.Label srOnly>Bank</Form.Label>
          <Form.Control
            value={bank}
            onChange={event => setBank(event.target.value)}
            required
            type="text"
            placeholder="Bank"
          />
        </Col>
        <Col xs="auto">
          <Form.Label srOnly>Name</Form.Label>
          <Form.Control
            value={name}
            onChange={event => setName(event.target.value)}
            required
            type="text"
            placeholder="Account name"
          />
        </Col>
        <Col xs="auto">
          <Form.Label srOnly>Initial Balance</Form.Label>
          <Form.Control
            value={initialBalance}
            onChange={event => setInitialBalance(parseInt(event.target.value))}
            required
            type="text"
            placeholder="Initial Balance"
          />
        </Col>
        <Col xs="auto">
          <Form.Label srOnly>Statement Format</Form.Label>
          <OverlayTrigger
            rootClose
            trigger="click"
            placement="bottom"
            overlay={statementFormatPopover}
          >
            <Form.Control
              value={statementFormat}
              onChange={event => setStatementFormat(event.target.value)}
              required
              type="text"
              placeholder="Statement Format"
            />
          </OverlayTrigger>
        </Col>
        <Col xs="auto">
          <Button variant="primary" type="submit" disabled={id.length === 0}>
            {id.length > 0 ? `Add account (${id})` : "Add account"}
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};

const Admin = ({ indexedParties, datasetDoc }) => {
  return (
    <div>
      <AddPartyForm
        newPartyAction={(id, name) =>
          datasetDoc
            .collection("parties")
            .doc(id)
            .set({ name: name })
        }
        usedIds={indexedParties.ids}
      />
      <hr />
      {indexedParties.ids.map(partyId => (
        <Party key={partyId} partyDoc={indexedParties.docs[partyId]} datasetDoc={datasetDoc} />
      ))}
    </div>
  );
};

const Party = ({ partyDoc, datasetDoc }) => {
  const [accountDocs] = useCollectionSubscription(
    datasetDoc,
    "parties/" + partyDoc.id + "/accounts"
  );
  return (
    !!accountDocs && (
      <div>
        <h1>
          {partyDoc.data().name} (<em>{partyDoc.id}</em>)
        </h1>
        <div style={{ marginLeft: "2rem" }}>
          <AddAccountForm
            usedIds={accountDocs.map(doc => doc.id)}
            newAccountAction={(id, name, bank, initialBalance, statementFormat) =>
              datasetDoc
                .collection("parties")
                .doc(partyDoc.id)
                .collection("accounts")
                .doc(id)
                .set({
                  name: name,
                  bank: bank,
                  initialBalance: initialBalance,
                  statementFormat: statementFormat,
                })
            }
          />
          <hr />
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Bank</th>
                <th>Initial Balance</th>
                <th>Statement Format</th>
              </tr>
            </thead>
            <tbody>
              {!!accountDocs &&
                accountDocs.map(accountDoc => (
                  <tr key={accountDoc.id}>
                    <td>{accountDoc.id}</td>
                    <td>{accountDoc.data().name}</td>
                    <td>{accountDoc.data().bank}</td>
                    <td>
                      <Amount pence={accountDoc.data().initialBalance} />
                    </td>
                    <td>{accountDoc.data().statementFormat}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    )
  );
};

export default Admin;
