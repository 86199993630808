import Papa from "papaparse";
import { StatementRow } from "../../StatementRow";
import { parseDate_dd_mmm_yyyy, parseDate_mmmm_yy } from "../dateParsing";
import { parsePennies } from "../parsePennies";
import TimeDate from "../../../../common/timeDate";

const groupRowsByType = parsedRows => {
  let openingRow = undefined;
  let closingRow = undefined;
  let interestRow = undefined;
  let paymentRows = [];
  parsedRows.forEach((row, index) => {
    if (row.Transactions === "Opening Outstanding Balance") {
      openingRow = { ...row, index };
    } else if (row.Transactions === "Outstanding Balance") {
      closingRow = { ...row, index };
    } else if (row.Transactions === "Interest Charged") {
      interestRow = { ...row, index };
    } else {
      paymentRows.push({ ...row, index });
    }
  });
  return { openingRow, closingRow, interestRow, paymentRows };
};

const parsePaymentRow = row => {
  const rowDate = parseDate_mmmm_yy(row.Date);
  const description =
    row.Transactions === "Payment received"
      ? `Payment (${TimeDate.formatMonthYear(rowDate)})`
      : row.Transactions;
  const amountAsString = row["Paid out"].length > 0 ? "-" + row["Paid out"] : row["Paid in"];
  const amount = parsePennies(amountAsString);
  return { id: row.index, description, amount };
};

const parseInterestRow = (interestRow, openingDate, closingDate, interestToDate) => ({
  id: interestRow.index,
  description: `Interest (${TimeDate.formatDate(openingDate)} - ${TimeDate.formatDate(
    closingDate
  )})`,
  amount: -parsePennies(interestRow["Paid out"]) - interestToDate,
});

const tomorrow = date => new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

const generatePaymentsStatementRow = (
  paymentRows,
  interestRow,
  openingDate,
  closingDate,
  openingBalance,
  closingRow,
  previousParsingMetadata
) => {
  const usePreviousMetadata =
    !!previousParsingMetadata &&
    previousParsingMetadata.asOfDate.getFullYear() === openingDate.getFullYear();
  const openingDateToUse = usePreviousMetadata
    ? tomorrow(previousParsingMetadata.asOfDate)
    : openingDate;
  const parsedInterestRow = parseInterestRow(
    interestRow,
    openingDateToUse,
    closingDate,
    usePreviousMetadata ? previousParsingMetadata.interestToDateThisYear : 0
  );
  const parsedPaymentRows = paymentRows.map(parsePaymentRow);
  const filteredParsedPaymentRows = usePreviousMetadata
    ? parsedPaymentRows.filter(row => !previousParsingMetadata.paymentIdsSeen.includes(row.id))
    : parsedPaymentRows;
  const descriptions = [...filteredParsedPaymentRows, parsedInterestRow];
  const netAmount = descriptions.reduce((soFar, sub) => soFar + sub.amount, 0);
  const calculatedBalance = openingBalance + netAmount;
  const importedBalance = -parsePennies(closingRow["Outstanding Balance:"]);

  return new StatementRow(
    descriptions[0].id,
    closingDate,
    closingDate.getDate() === 31 && closingDate.getMonth() === 11
      ? `Payments for ${closingDate.getFullYear()}`
      : `Payments (${TimeDate.formatDate(openingDateToUse)} - ${TimeDate.formatDate(closingDate)})`,
    descriptions,
    netAmount,
    importedBalance,
    calculatedBalance,
    usePreviousMetadata
      ? {
          asOfDate: closingDate,
          interestToDateThisYear:
            previousParsingMetadata.interestToDateThisYear + parsedInterestRow.amount,
          paymentIdsSeen: parsedPaymentRows.map(row => row.id),
        }
      : {
          asOfDate: closingDate,
          interestToDateThisYear: parsedInterestRow.amount,
          paymentIdsSeen: parsedPaymentRows.map(row => row.id),
        },
    [...paymentRows, interestRow]
  );
};

const generateAdvanceStatementRow = (headPaymentRow, closingDate, openingBalance) => {
  const parsedAdvanceRow = parsePaymentRow(headPaymentRow);
  return new StatementRow(
    parsedAdvanceRow.id,
    closingDate,
    "Initial advance",
    [],
    parsedAdvanceRow.amount,
    openingBalance + parsedAdvanceRow.amount,
    openingBalance + parsedAdvanceRow.amount,
    {},
    headPaymentRow
  );
};

const parseNationwideMortgageStatement = (csv, dbFinalBalance, previousParsingMetadata) => {
  const rateHistoryLines = csv.substring(
    csv.indexOf("Interest rate history") - 1,
    csv.indexOf("Repayment method") - 1
  );
  const parsedRateHistory = Papa.parse(rateHistoryLines, {
    skipEmptyLines: "greedy",
    header: true,
  }).data;
  const firstInterestDate = parseDate_dd_mmm_yyyy(parsedRateHistory[0]["Interest rate history"]);

  const transactionLines = csv.substring(
    csv.indexOf("Date") - 1,
    csv.indexOf("Interest rate history") - 1
  );
  const parsedTransactions = Papa.parse(transactionLines, {
    skipEmptyLines: "greedy",
    header: true,
  }).data;
  const openingBalance = dbFinalBalance;
  const { openingRow, closingRow, interestRow, paymentRows } = groupRowsByType(parsedTransactions);
  const openingDate = parseDate_dd_mmm_yyyy(openingRow.Date);
  const closingDate = parseDate_dd_mmm_yyyy(closingRow.Date);

  const [headPaymentRow, ...tailPaymentRows] = paymentRows;
  if (headPaymentRow.Transactions === "Advances") {
    const advanceStatementRow = generateAdvanceStatementRow(
      headPaymentRow,
      firstInterestDate,
      openingBalance
    );
    const paymentsStatementRow = generatePaymentsStatementRow(
      tailPaymentRows,
      interestRow,
      openingDate,
      closingDate,
      advanceStatementRow.calculatedBalance,
      closingRow,
      previousParsingMetadata
    );
    return [advanceStatementRow, paymentsStatementRow];
  } else {
    const paymentsStatementRow = generatePaymentsStatementRow(
      paymentRows,
      interestRow,
      openingDate,
      closingDate,
      openingBalance,
      closingRow,
      previousParsingMetadata
    );
    return [paymentsStatementRow];
  }
};

export default parseNationwideMortgageStatement;
