export const parseDate_ddmmyyyy = date => {
  const year = parseInt(date.substring(6, 10));
  const month = date.substring(3, 5) - 1;
  const day = parseInt(date.substring(0, 2));
  return new Date(year, month, day, 0, 0, 0, 0);
};

const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const parseDate_dd_mmm_yyyy = date => {
  const year = parseInt(date.substring(7, 11));
  const month = shortMonths.indexOf(date.substring(3, 6));
  const day = parseInt(date.substring(0, 2));
  return new Date(year, month, day, 0, 0, 0, 0);
};

export const parseDate_mmmm_yy = date => {
  const year = 2000 + parseInt(date.substring(date.length - 2));
  const month = shortMonths.indexOf(date.substring(0, 3));
  return new Date(year, month, 1, 0, 0, 0, 0);
};
