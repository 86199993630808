const weekdays = new Array(7);
weekdays[0] = "Sun";
weekdays[1] = "Mon";
weekdays[2] = "Tues";
weekdays[3] = "Weds";
weekdays[4] = "Thurs";
weekdays[5] = "Fri";
weekdays[6] = "Sat";

const formatDateWithDay = date => `${weekdays[date.getDay()]} ${formatDate(date)}`;

const formatDate = date => {
  let day = "" + date.getDate();
  let month = "" + (date.getMonth() + 1);
  let year = date.getFullYear();

  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  return `${day}/${month}/${year}`;
};

const formatMonthYear = date => {
  let month = "" + (date.getMonth() + 1);
  let year = date.getFullYear();

  if (month.length < 2) month = "0" + month;

  return `${month}/${year}`;
};

const TimeDate = { formatDateWithDay, formatDate, formatMonthYear };

export default TimeDate;
