import React from "react";
import SignoutButton from "../auth/SignoutButton";
import Spinner from "react-bootstrap/Spinner";
import NavWrapper from "../nav/NavWrapper";
import useCollectionSubscription from "@wellingtonsteve/jscommon/useCollectionSubscription";
import Statements from "./statements/Statements";
import Admin from "./admin/Admin";

const If = NavWrapper.If;

const Loading = () => (
  <div>
    <Spinner animation="grow" variant="danger" /> <Spinner animation="grow" variant="warning" />{" "}
    <Spinner animation="grow" variant="info" /> <Spinner animation="grow" variant="success" />
  </div>
);

const LoadingWithCaption = ({ caption }) => (
  <div style={{ width: "100%", textAlign: "center", margin: "1rem" }}>
    <Loading />
    <h1>{caption}</h1>
  </div>
);

const LoggedInApp = ({ logOutAction, currentUser, datasetDoc }) => {
  if (datasetDoc === undefined) {
    return <LoadingWithCaption caption={`Hi ${currentUser.displayName}. Logging in...`} />;
  } else {
    return (
      <DatasetDocLoaded
        logOutAction={logOutAction}
        currentUser={currentUser}
        datasetDoc={datasetDoc}
      />
    );
  }
};

const DatasetDocLoaded = ({ logOutAction, currentUser, datasetDoc }) => {
  const [partyDocs] = useCollectionSubscription(datasetDoc, "parties");

  if (partyDocs === undefined) {
    return <LoadingWithCaption caption={`Hi ${currentUser.displayName}. Loading...`} />;
  }

  const indexedParties = partyDocs.reduce(
    ({ ids, docs }, doc) => ({
      ids: [...ids, doc.id],
      docs: { ...docs, [doc.id]: doc },
    }),
    { ids: [], docs: {} }
  );

  return (
    <NavWrapper title={`Money (${datasetDoc.id})`}>
      <If
        tabName="Statements"
        default
        ifSelectedShow={() => (
          <Statements indexedParties={indexedParties} datasetDoc={datasetDoc} />
        )}
      />
      <If
        tabName="Admin"
        ifSelectedShow={() => <Admin indexedParties={indexedParties} datasetDoc={datasetDoc} />}
      />
      <SignoutButton onClick={logOutAction} />
    </NavWrapper>
  );
};

export default LoggedInApp;
