import React, { Children, cloneElement, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const If = ({ tabName, selectedContext, ifSelectedShow, setSelectedContext }) => {
  if (tabName === selectedContext.tabName) {
    return cloneElement(ifSelectedShow(), {
      setSelectedContext,
      ...selectedContext.extraProps,
    });
  } else {
    return null;
  }
};

const NavWrapper = ({ title, children }) => {
  const childrenArray = Children.map(children, i => i);
  const ifChildren = childrenArray.filter(child => child.type === If);
  const defaultTabName = ifChildren.find(child => child.props.default).props.tabName;
  const [selectedContext, setSelectedContext] = useState({
    tabName: defaultTabName,
    extraProps: {},
  });

  const convertedChildren = childrenArray.map(child =>
    child.type === If ? (
      <Nav.Link key={child.props.tabName} eventKey={child.props.tabName}>
        {child.props.tabName}
      </Nav.Link>
    ) : (
      child
    )
  );

  return (
    <div>
      <Navbar variant="light" expand="sm" id="bn-navBar" collapseOnSelect="true">
        <Navbar.Brand>{title}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav
            variant="pills"
            activeKey={selectedContext.tabName}
            onSelect={selected => setSelectedContext({ tabName: selected, extraProps: {} })} // Don't just pass setSelectedTab as handler. onSelect has extra args
          >
            {convertedChildren}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div style={{ padding: ".5rem 1rem" }}>
        {ifChildren.map(child => cloneElement(child, { selectedContext, setSelectedContext }))}
      </div>
    </div>
  );
};

NavWrapper.If = If;

export default NavWrapper;
