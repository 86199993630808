const translateParsingMetadata = parsingMetadata =>
  parsingMetadata === undefined || parsingMetadata === null
    ? undefined
    : parsingMetadata.asOfDate === undefined || parsingMetadata.asOfDate === null
    ? parsingMetadata
    : {
        ...parsingMetadata,
        asOfDate: parsingMetadata.asOfDate.toDate(),
      };

export class StatementRow {
  constructor(
    id,
    date,
    description,
    subDescriptions,
    amount,
    importedBalance,
    calculatedBalance,
    parsingMetadata,
    rawData,
    tempData = {}
  ) {
    this.id = id;
    this.date = date;
    this.description = description;
    this.subDescriptions = subDescriptions;
    this.amount = amount;
    this.importedBalance = importedBalance;
    this.calculatedBalance = calculatedBalance;
    this.parsingMetadata = parsingMetadata;
    this.rawData = rawData;
    this.tempData = tempData;
  }

  static fromDoc = (doc, seenValidationText, validationText) => {
    return new StatementRow(
      doc.id,
      doc.data().date.toDate(),
      doc.data().description,
      doc.data().subDescriptions || [],
      doc.data().amount,
      doc.data().importedBalance,
      doc.data().calculatedBalance,
      translateParsingMetadata(doc.data().parsingMetadata),
      doc.data().rawData,
      { seenValidationText, validationText }
    );
  };
}
