import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import { Amount } from "../common/Amount";
import React, { useState } from "react";
import TimeDate from "../../common/timeDate";
import Button from "react-bootstrap/Button";

const DetailLinesPopover = ({ statementRow, children }) => {
  const detailsPopover = (
    <Popover id="detailsPopover">
      <Popover.Title as="h3">{statementRow.id}</Popover.Title>
      <Popover.Content>
        <pre>{JSON.stringify(statementRow.rawData, null, 2)}</pre>
      </Popover.Content>
    </Popover>
  );
  return (
    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={detailsPopover}>
      <span>{children}</span>
    </OverlayTrigger>
  );
};

const BalancePopover = ({ statementRow, children, recordValidationText }) => {
  const balancePopover = (
    <Popover id="balancePopover">
      <Popover.Content>
        {statementRow.importedBalance === undefined || statementRow.importedBalance === null || (
          <p>
            <strong>Imported Balance</strong>: <Amount pence={statementRow.importedBalance} />
          </p>
        )}
        {statementRow.tempData === undefined || statementRow.tempData.validationText === undefined || (
          <p>
            <strong>Validation</strong>: {statementRow.tempData.validationText}
          </p>
        )}
        {!!recordValidationText &&
          (statementRow.tempData === undefined ||
            statementRow.tempData.validationText === undefined) && (
            <p>
              <Button
                onClick={() => {
                  const validationText = prompt("Validation Text:");
                  if (validationText !== null) {
                    recordValidationText(validationText);
                  }
                }}
              >
                Validate this
              </Button>
            </p>
          )}
        <p>
          <strong>Calculated Balance</strong>: <Amount pence={statementRow.calculatedBalance} />
        </p>
      </Popover.Content>
    </Popover>
  );
  return (
    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={balancePopover}>
      <span>{children}</span>
    </OverlayTrigger>
  );
};

const DatePopover = ({ date, children }) => {
  const datePopover = (
    <Popover id="datePopover">
      <Popover.Content>{date.toString()}</Popover.Content>
    </Popover>
  );
  return (
    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={datePopover}>
      <span>{children}</span>
    </OverlayTrigger>
  );
};

const balanceValidationStyle = (calculatedBalance, importedBalance, tempData) => {
  if (calculatedBalance === importedBalance || !!tempData.seenValidationText) {
    return {};
  }
  return {
    backgroundColor: importedBalance === undefined || importedBalance === null ? "coral" : "pink",
  };
};

const StatementTableRow = ({ statementRow, defaultCollapsedSubs = true, recordValidationText }) => {
  const [collapsedSubs, setCollapsedSubs] = useState(defaultCollapsedSubs);
  return (
    <tr>
      <td>
        <DatePopover date={statementRow.date}>
          {TimeDate.formatDateWithDay(statementRow.date)}
        </DatePopover>
      </td>
      <td>
        {statementRow.subDescriptions.length > 0 ? (
          <span>
            <span onClick={() => setCollapsedSubs(!collapsedSubs)} className="bullet">
              {collapsedSubs ? "▶" : "▼"} {statementRow.description}
            </span>
            {collapsedSubs || (
              <DetailLinesPopover statementRow={statementRow}>
                <MiniTable rows={statementRow.subDescriptions} />
              </DetailLinesPopover>
            )}
          </span>
        ) : (
          <DetailLinesPopover statementRow={statementRow}>
            {statementRow.description}
          </DetailLinesPopover>
        )}
      </td>
      <td className="amountCell">
        <Amount pence={statementRow.amount} />
      </td>
      <td
        className="amountCell"
        style={balanceValidationStyle(
          statementRow.calculatedBalance,
          statementRow.importedBalance,
          statementRow.tempData
        )}
      >
        <BalancePopover statementRow={statementRow} recordValidationText={recordValidationText}>
          <Amount pence={statementRow.calculatedBalance} />
        </BalancePopover>
      </td>
    </tr>
  );
};

const MiniTable = ({ rows }) => (
  <Table striped bordered hover size="sm">
    <colgroup>
      <col span="1" style={{ width: "80%" }} />
      <col span="1" style={{ width: "20%" }} />
    </colgroup>
    <thead>
      <tr>
        <th>Description</th>
        <th>Amount</th>
      </tr>
    </thead>
    <tbody>
      {rows.map(row => (
        <MiniRow key={row.id} row={row} />
      ))}
    </tbody>
  </Table>
);

const MiniRow = ({ row }) => {
  return (
    <tr>
      <td>{row.description}</td>
      <td className="amountCell">
        <Amount pence={row.amount} />
      </td>
    </tr>
  );
};

export const StatementTable = ({
  statementRows,
  initialBalance,
  extraRows,
  children,
  recordValidationText,
}) => (
  <Table striped bordered hover size="sm">
    <colgroup>
      <col span="1" style={{ width: "15%" }} />
      <col span="1" style={{ width: "65%" }} />
      <col span="1" style={{ width: "10%" }} />
      <col span="1" style={{ width: "10%" }} />
    </colgroup>
    <thead>
      <tr>
        <th>Date</th>
        <th>Description</th>
        <th>Amount</th>
        <th>Calculated Balance</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colSpan="3">Starting balance</td>
        <td className="amountCell">
          <Amount pence={initialBalance} />
        </td>
      </tr>
      {statementRows.map(statementRow => (
        <StatementTableRow
          key={statementRow.id}
          statementRow={statementRow}
          recordValidationText={validationText =>
            recordValidationText(statementRow.id, validationText)
          }
        />
      ))}
      {children}
      {!!extraRows &&
        extraRows.map(statementRow => (
          <StatementTableRow
            key={statementRow.id}
            statementRow={statementRow}
            defaultCollapsedSubs={false}
          />
        ))}
    </tbody>
  </Table>
);
